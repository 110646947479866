<template>
  <div>
    <el-main class="sensor">
      <el-container class="shopList">
        <div style="padding-bottom: 20px">
          <el-form :inline="true" :model="mQuery" class="demo-form-inline">
            <el-col :span="4" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="公式名称"
                  v-model="mQuery.keyword"
                  class="input-with-select"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="mSearch"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="1" class="tl mr10">
              <el-form-item size="medium">
                <el-button type="success" @click="addDialogVisible = true"
                  >新增</el-button
                >
              </el-form-item>
            </el-col>
          </el-form>
          <el-table :data="modelList" style="width: 100%" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="formulaName" label="公式名称" width="180">
            </el-table-column>
            <el-table-column
              prop="sensorNum"
              label="公式所需传感器个数"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="radixPoint"
              label="结果保留小数点位数"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="formula" label="公式详情" width="180">
            </el-table-column>
            <el-table-column prop="createdTime" label="创建时间" width="180">
              <template slot-scope="scope">
                {{ scope.row.createdTime.replace("T", " ") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <div class="df aic jca" style="padding: 0 20%">
                  <el-button
                    type="text"
                    class="disib red"
                    size="mini"
                    @click="delItem(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="i-page disib mt20 pagination-box">
            <el-pagination
              background
              :page-size="15"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="mCurrentPage"
              @current-change="mCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-container>
      <el-dialog
        title="新增公式"
        :visible.sync="addDialogVisible"
        width="60%"
        :before-close="handleClose"
        @closed="closeDialog"
      >
        <div style="padding: 20px; width: 80%; text-align: left !important">
          <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="公式名称" prop="name">
              <el-input v-model="form.name" style="width: 50%"></el-input>
            </el-form-item>
            <el-form-item label="公式类型">
              <el-radio v-model="radio" :label="1" disabled >接口</el-radio>
              <el-radio v-model="radio" :label="0">输入公式</el-radio>
            </el-form-item>
            <el-form-item label="公式详情">
              <div v-if="radio === 0" style="width: 800px; height: 300px">
                <el-card class="box-card">
                  <div slot="header" class="clearfix" style="width: 70%">
                    <div class="df aic jcb">
                      <span>结果保留小数点后位数</span>
                      <el-input-number
                        v-model="form.num1"
                        @change="(value) => handleChange(0, value)"
                        :min="0"
                        :max="10"
                        size="mini"
                        label="描述文字"
                      ></el-input-number>
                    </div>
                    <div class="df aic jcb">
                      <span>公式中调用的传感器个数</span>
                      <el-input-number
                        v-model="form.num2"
                        @change="(value) => handleChange(1, value)"
                        :min="1"
                        size="mini"
                        label="描述文字"
                      ></el-input-number>
                    </div>
                  </div>
                  <div>
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="click"
                      v-if="!form.algorithm.length"
                    >
                      <div style="padding: 20px">
                        <div class="df" style="flex-wrap: wrap">
                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'value')"
                              >常量</el-button
                            >
                          </div>
                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', '+')"
                              >+</el-button
                            >
                          </div>

                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', '-')"
                              >-</el-button
                            >
                          </div>

                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', '*')"
                              >*</el-button
                            >
                          </div>
                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', '/')"
                              >/</el-button
                            >
                          </div>
                          <!-- <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm('signal', '%')"
                              >%</el-button
                            >
                          </div> -->
                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', '(')"
                              >(</el-button
                            >
                          </div>
                          <div class="card-btn-item">
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'signal', ')')"
                              >)</el-button
                            >
                          </div>
                          <div
                            class="card-btn-item"
                            v-for="o in form.num2"
                            :key="o"
                          >
                            <el-button
                              size="mini"
                              type="primary"
                              style="margin-bottom: 10px"
                              @click="addAlgorithm(0, 'sensor', o)"
                              >传感器{{ o }}</el-button
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        style="color: #aaa; cursor: pointer; width: 14%"
                        slot="reference"
                      >
                        请点击输入
                      </div>
                    </el-popover>
                    <div class="df" style="flex-wrap: wrap">
                      <div
                        v-for="(item, index) in form.algorithm"
                        :key="index"
                        class="data-box df aic jcc"
                        :class="item.type + '-box'"
                        :visible-arrow="false"
                      >
                        <el-popover
                          placement="bottom"
                          width="60"
                          trigger="hover"
                          :popper-class="item.type + '-box'"
                        >
                          <div
                            class="df aic jcb"
                            style="
                              padding: 10px;
                              border-radius: 5px;
                              color: #fff;
                            "
                          >
                            <el-popover
                              placement="bottom"
                              width="400"
                              trigger="manual"
                              v-model="item.visible"
                            >
                              <div style="padding: 20px">
                                <div class="df" style="flex-wrap: wrap">
                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'value');
                                      "
                                      >常量</el-button
                                    >
                                  </div>
                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'signal', '+');
                                      "
                                      >+</el-button
                                    >
                                  </div>

                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        addAlgorithm(index + 1, 'signal', '-');
                                        item.visible = false;
                                      "
                                      >-</el-button
                                    >
                                  </div>

                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'signal', '*');
                                      "
                                      >*</el-button
                                    >
                                  </div>
                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'signal', '/');
                                      "
                                      >/</el-button
                                    >
                                  </div>
                                  <!-- <div class="card-btn-item">
                                <el-button
                                  size="mini"
                                  type="primary"
                                  style="margin-bottom: 10px"
                                  @click="addAlgorithm('signal', '%')"
                                  >%</el-button
                                >
                              </div> -->
                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'signal', '(');
                                      "
                                      >(</el-button
                                    >
                                  </div>
                                  <div class="card-btn-item">
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        addAlgorithm(index + 1, 'signal', ')');
                                        item.visible = false;
                                      "
                                      >)</el-button
                                    >
                                  </div>
                                  <div
                                    class="card-btn-item"
                                    v-for="o in form.num2"
                                    :key="o"
                                  >
                                    <el-button
                                      size="mini"
                                      type="primary"
                                      style="margin-bottom: 10px"
                                      @click="
                                        item.visible = false;
                                        addAlgorithm(index + 1, 'sensor', o);
                                      "
                                      >传感器{{ o }}</el-button
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                style="cursor: pointer"
                                class="df aic"
                                slot="reference"
                                @click="item.visible = true"
                              >
                                <i
                                  class="el-icon-circle-plus"
                                  style="font-size: 14px"
                                  >新增</i
                                >
                              </div>
                            </el-popover>
                            <div
                              style="padding-left: 20px; cursor: pointer"
                              class="df aic"
                              @click="delAlgorithm(index)"
                            >
                              <i class="el-icon-error" style="font-size: 14px"
                                >移除</i
                              >
                            </div>
                          </div>

                          <template slot="reference">
                            <div v-if="item.type === 'value'">
                              {{ item.value }}
                            </div>
                            <div v-else-if="item.type === 'signal'">
                              {{ item.value }}
                            </div>
                            <div v-else-if="item.type === 'sensor'">
                              传感器{{ item.value }}
                            </div>
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
              <div
                v-if="radio === 1"
                style="width: 600px; height: 300px; background-color: blue"
              ></div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
export default {
  created() {
    this.typeOptions = this.typeList.map((v) => {
      return {
        value: v,
        label: v,
      };
    });
    this.companyId = localStorage.getItem("company_id");
    this.getList();
  },
  data() {
    return {
      form: {
        name: "",
        num1: 2,
        num2: 1,
        algorithm: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入公式名称",
            trigger: "blur",
          },
        ],
      },
      timer: null,
      // checkedIndex: 0,
      radio: 0,
      addDialogVisible: false,
      typeOptions: null,
      typeList: ["温度"],
      modelList: [],
      modelTypeOptions: [],
      editForm: {
        editName: "",
        companyName: "",
        companyId: null,
        editValue: [-20, 40],
      },
      total: 0,
      createPopover: false,
      mQuery: {
        keyword: "",
        page: 1,
        limit: 15,
        type: 0,
      },
      createFormData: {
        name: "",
        companyName: "",
        companyId: null,
        info: {
          type: "温度",
          value: [-20, 60],
        },
      },
      createRule: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        companyId: [{ required: true, message: "请选择公司" }],
        editName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      },
      mCurrentPage: 1,
      companyId: null,
      companyName: null,
      form3: {
        device_id: "",
        device_name: "",
        company_id: "",
        company_name: "",
        type: "",
        unit: "",
        max_value: "",
        min_value: "",
        pager_offset: "0",
        pager_openset: "10",
      },
    };
  },

  computed: {
    algorithmInfo() {
      let sensorArr = [];
      let isAble = true;
      for (let i = 0; i < this.form.num2; i++) {
        sensorArr.push("sensor" + i);
      }
      let finalData = "";
      this.form.algorithm.forEach((v, i) => {
        finalData += v.value;
        switch (v.type) {
          case "value":
            {
              if (i !== 0 && i !== this.form.algorithm.length - 1) {
                if (
                  !(this.form.algorithm[i - 1].type === "signal") ||
                  !(this.form.algorithm[i + 1].type === "signal")
                ) {
                  isAble = false;
                }
              } else if (i !== 0) {
                if (!(this.form.algorithm[i - 1].type === "signal")) {
                  isAble = false;
                }
              } else if (i !== this.form.algorithm.length - 1) {
                if (!(this.form.algorithm[i + 1].type === "signal")) {
                  isAble = false;
                }
              }
            }
            break;
          case "signal":
            {
              if (!["(", ")"].includes(v.value)) {
                if (i !== 0 && i !== this.form.algorithm.length - 1) {
                  if (
                    (this.form.algorithm[i - 1].type === "signal" &&
                      !["(", ")"].includes(this.form.algorithm[i - 1].value)) ||
                    (this.form.algorithm[i + 1].type === "signal" &&
                      !["(", ")"].includes(this.form.algorithm[i + 1].value))
                  ) {
                    isAble = false;
                  }
                } else if (i !== 0) {
                  if (
                    this.form.algorithm[i - 1].type === "signal" &&
                    !["(", ")"].includes(this.form.algorithm[i - 1].value)
                  ) {
                    isAble = false;
                  }
                } else if (i !== this.form.algorithm.length - 1) {
                  if (
                    this.form.algorithm[i + 1].type === "signal" &&
                    !["(", ")"].includes(this.form.algorithm[i + 1].value)
                  ) {
                    isAble = false;
                  }
                }
              } else if (v.value === "(") {
                if (i !== this.form.algorithm.length - 1) {
                  if (this.form.algorithm[i + 1].type === "signal") {
                    isAble = false;
                  }
                }
              } else if (v.value === ")") {
                if (i !== 0) {
                  if (this.form.algorithm[i - 1].type === "signal") {
                    isAble = false;
                  }
                }
              }
            }
            break;
          case "sensor":
            {
              if (sensorArr.includes("sensor" + (v.value - 1))) {
                sensorArr = sensorArr.filter(
                  (item) => item !== "sensor" + (v.value - 1)
                );
              }
              if (i !== 0 && i !== this.form.algorithm.length - 1) {
                if (
                  !(this.form.algorithm[i - 1].type === "signal") ||
                  !(this.form.algorithm[i + 1].type === "signal")
                ) {
                  isAble = false;
                }
              } else if (i !== 0) {
                if (!(this.form.algorithm[i - 1].type === "signal")) {
                  isAble = false;
                }
              } else if (i !== this.form.algorithm.length - 1) {
                if (!(this.form.algorithm[i + 1].type === "signal")) {
                  isAble = false;
                }
              }
            }
            break;
        }
      });
      // console.log(isAble,'格式');
      // console.log(
      //   this.form.algorithm[0].type !== "signal" ||
      //     this.form.algorithm[0].value === "("
      // ,'格式首元素');
      // console.log(
      //   this.form.algorithm[this.form.algorithm.length - 1].type !== "signal" ||
      //     this.form.algorithm[this.form.algorithm.length - 1].value === ")"
      // ,'格式尾元素');
      // console.log(this.bracesStatus(finalData));
      return {
        isAble:
          isAble &&
          (this.form.algorithm[0].type !== "signal" ||
            this.form.algorithm[0].value === "(") &&
          (this.form.algorithm[this.form.algorithm.length - 1].type !==
            "signal" ||
            this.form.algorithm[this.form.algorithm.length - 1].value ===
              ")") &&
          this.bracesStatus(finalData),
        sensor: sensorArr.length === 0,
      };
    },
  },
  methods: {
    //括号嵌套校验
    bracesStatus(string) {
      let stack = [],
        mapping = { ")": "(", "]": "[", "}": "{" };
      for (let c of string) {
        if (/[\(\[\{]/.test(c)) {
          stack.push(c);
        } else if (/[\)\]\}]/.test(c)) {
          if (stack.length === 0) return false;
          if (stack[stack.length - 1] != mapping[c]) {
            return false;
          } else {
            stack.pop();
          }
        }
      }
      return stack.length === 0;
    },

    closeDialog() {
      this.form = {
        name: "",
        num1: 2,
        num2: 1,
        algorithm: [],
      };
      this.radio = 0;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log(this.algorithmInfo);
          if (!this.algorithmInfo.isAble) {
            this.$message({
              message: "请设置正确的公式格式",
              type: "warning",
            });
            return;
          } else if (!this.algorithmInfo.sensor) {
            this.$message({
              message: "公式所需的传感器数量与公式中的传感器不匹配",
              type: "warning",
            });
            return;
          }
          let data = {
            type: this.radio,
          };
          data.formulaName = this.form.name;
          data.formula = "";
          data.constNum = 0;
          data.sensorNum = this.form.num2;
          data.radixPoint = this.form.num1;
          this.form.algorithm.forEach((v) => {
            switch (v.type) {
              case "value":
                {
                  data.formula += "num" + data.constNum;
                  data.constNum++;
                }
                break;
              case "signal":
                {
                  data.formula += v.value;
                }
                break;
              case "sensor":
                {
                  data.formula += "sensor" + (v.value - 1);
                }
                break;
            }
          });
          // console.log(data, 123);
          api.post("/v1/wx/formula/add", data).then((res) => {
            if (res.code === 200) {
              console.log(res.data);
              this.addDialogVisible = false;
              this.getList();
            }
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(type, value) {
      type ? (this.form.num2 = value) : (this.form.num1 = value);
    },
    handleClose() {},
    delAlgorithm(index) {
      this.form.algorithm.splice(index, 1);
    },
    addAlgorithm(i, type, index) {
      switch (type) {
        case "value":
          {
            this.form.algorithm.splice(i, 0, {
              type,
              value: "常量",
              visible: false,
            });
          }
          break;
        case "signal":
          {
            this.form.algorithm.splice(i, 0, {
              type,
              value: index,
              visible: false,
            });
          }
          break;
        case "sensor":
          {
            this.form.algorithm.splice(i, 0, {
              type,
              value: index,
              visible: false,
            });
          }
          break;
      }
    },

    popoverShow(row) {
      this.editForm.editName = row.modeName;
      this.editForm.editValue = [
        row.minValue.slice(0, -1),
        row.maxValue.slice(0, -1),
      ];
      console.log(this.editForm.editValue);
    },
    closeEPopover(row) {
      row.row = false;
    },
    sliderChange(e, index) {
      if (index) {
        this.editForm.editValue = e;
      } else {
        this.createFormData.info.value = e;
      }
    },
    handleClear() {
      this.createFormData.companyId = null;
      this.createFormData.companyName = "";
    },
    closePopover() {
      this.$refs["createForm"].resetFields();
      this.createPopover = false;
      this.createFormData.name = "";
      this.createFormData.type = "温度";
      this.createFormData.companyName = "";
      this.createFormData.companyId = null;
      this.form3.company_name = "";
      this.createFormData.info.value = [-40, 100];
    },
    handleSelect(item) {
      this.createFormData.companyName = item.company_name;
      this.createFormData.companyId = item.company_id;
      console.log(item, this.createFormData);

      this.form3.company_id = item.company_id;
      this.form3.company_name = item.company_name;
    },
    //根据库房名称查询信息
    querySearchAsync(queryString, cb) {
      console.log(queryString);
      if (queryString) {
        this.form3.company_name = queryString;
        axios.get("/pc/company/signList/2", {company_name:this.form3.company_name}).then((response) => {
          let results = response.data.company_list;
          cb(results);
        });
      } else {
        cb([]);
      }
    },
    createModel() {
      console.log(this.createFormData);
      this.$refs["createForm"].validate((valid) => {
        if (valid) {
          let data = {
            modeName: this.createFormData.name,
            companyId: this.createFormData.companyId,
          };
          data.modeType = this.typeList.indexOf(this.createFormData.info.type);
          data.maxValue = this.createFormData.info.value[1];
          data.minValue = this.createFormData.info.value[0];
          api.post(`/v1/pc/deviceMode/save`, data).then((res) => {
            if (res.code == 200) {
              this.$refs["createForm"].resetFields();
              this.createPopover = false;
              this.createFormData.name = "";
              this.createFormData.type = "温度";
              this.createFormData.info.value = [-40, 100];

              this.createFormData.companyName = "";
              this.createFormData.companyId = null;
              this.form3.company_name = "";
              this.getModelList();
            }
          });
        } else {
          return false;
        }
      });
    },
    getList() {
      api.get(`/v1/wx/formula/list`, this.mQuery).then((res) => {
        if (res.code == 200) {
          this.total = res.total;
          this.modelList = res.data;
        }
      });
    },
    // modelEdit(row) {
    //   api
    //     .post(`/v1/pc/deviceMode/update`, {
    //       companyId: this.companyId,
    //       id: row.id,
    //       modeType: this.typeList.indexOf(row.modeType),
    //       modeName: this.editForm.editName,
    //       minValue: this.editForm.editValue[0],
    //       maxValue: this.editForm.editValue[1],
    //     })
    //     .then((res) => {
    //       if (res.code == 200) {
    //         this.mQuery.page = 1;
    //         this.mCurrentPage = 1;
    //         this.getModelList();
    //       }
    //     });
    // },
    delItem(row) {
      api
        .post(`/v1/wx/formula/delete`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.mQuery.page = 1;
            this.mCurrentPage = 1;
            this.getList();
          }
        });
    },
    mSearch() {
      this.mQuery.page = 1;
      this.mCurrentPage = 1;
      this.getList();
    },
    mCurrentChange(page) {
      this.mQuery.page = page;
      this.getModelList();
    },
  },
};
</script>

<style lang="scss">
.sensor {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .el-table__empty-block {
  width: auto !important;
}
.el-card__header {
  height: 120px !important;
}
.jca {
  justify-content: space-around;
}
.jcb {
  justify-content: space-between;
}
.jcc {
  justify-content: center;
}
.aic {
  align-items: center;
}
.card-btn-item {
  margin: 0 10px;
}
.data-box {
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  padding: 5px;
  font-size: 24px;
  border-radius: 5px;
  color: #fff;
  margin-right: 10px;
}

.sensor-box {
  background-color: #4f91e2;
}
.signal-box {
  background-color: #ebbb4d;
}
.value-box {
  background-color: #ea8848;
}
</style>